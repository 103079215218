// Parallax Script
const parallax = () => {
  const sections = document.querySelectorAll("section")
  const topViewport = window.pageYOffset
  const midViewport = topViewport + window.innerHeight / 2

  sections.forEach(section => {
    const topSection = section.offsetTop
    const midSection = topSection + section.offsetHeight / 2
    const distanceToSection = midViewport - midSection
    const parallaxTags = section.querySelectorAll(`[data-parallax]`)

    // loop over each parallax tag
    parallaxTags.forEach(tag => {
      const speed = parseFloat(tag.getAttribute("data-parallax"))
      tag.style.transform = `translate(0, ${distanceToSection * speed}px)`
    })
  })
}

export default parallax
