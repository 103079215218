// Apply IntersectionObserver to all image wrappers
const handleIntersectImg = () => {
  if (typeof document !== "undefined") {
    const sample = document.querySelectorAll(".imageWrapper")

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            entry.target.classList.add("imageAnimation")
          } else {
            entry.target.classList.remove("imageAnimation")
          }
        })
      },
      {
        rootMargin: "0px 0px",
      }
    )

    sample.forEach(img => {
      observer.observe(img)
    })
  }
}

export default handleIntersectImg
