import css from "@emotion/css"
import dimensions from "styles/dimensions"

const typeStyles = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1em 0 0.15em 0;
  }

  p {
    margin: 0;
  }

  h1 {
    font-size: 4.19em;
    line-height: 1;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 2.25em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
      font-size: 2em;
    }
  }

  h2 {
    font-size: 3.14em;
    line-height: 1;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
      font-size: 2.25em;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
      font-size: 2em;
    }
  }

  h3 {
    line-height: 1.2;
    font-size: 2.29em;
  }

  h5 {
    line-height: 20px;
    font-size: 1.43;
  }

  h6 {
    font-size: 1em;
    margin: 0;
  }

  p {
    line-height: 1.4;
  }

  a {
    &:hover {
      cursor: pointer;
    }
  }
`

export default typeStyles
