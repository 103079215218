import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import colors from "styles/colors"
import dimensions from "styles/dimensions"
import Logo from "components/_ui/Logo"

const HeaderContainer = styled("div")`
  padding: ${dimensions.pagePaddingTop}px calc(100vw / 22.5) 3em
    calc(100vw / 22.5);
  position: fixed;
  top: 0;
  z-index: 3;
  width: 100%;

  @media (max-width: 768px) {
    padding: ${dimensions.defaultSpacing}px calc(100vw / 22.5) 1em
      calc(100vw / 22.5);
  }
`

const HeaderContent = styled("div")`
  display: flex;
  justify-content: space-between;
  line-height: 12px;

  & svg {
    width: 100%;
    max-height: 40px;
  }

  @media (max-width: ${dimensions.maxwidthLaptop}px) {
    flex-direction: column;
  }
`

const HeaderLinks = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: ${dimensions.pagePaddingTop}px;
  max-width: 500px;

  @media (max-width: ${dimensions.maxwidthLaptop}px) {
    padding-top: ${dimensions.defaultSpacing}px;
    padding-left: 0px;
  }

  a {
    color: currentColor;
    text-decoration: none;
    border-bottom: 3px solid transparent;
    font-weight: 600;
    font-size: 18px;
    height: 100%;
    padding-bottom: 1.25em;
    padding-top: 0;
    display: block;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media (max-width: 768px) {
      font-size: 16px;
    }

    @media (max-width: 420px) {
      font-size: 14px;
    }

    &:visited {
      color: currentColor;
    }

    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 3px;
      background: transparent;
      top: 18px;
      right: 0%;
      transition: 100ms ease-in-out background;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover {
      &:after {
        background: ${colors.grey900};
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }

    &.Link--is-active {
      &:after {
        background: ${colors.grey900};
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        transition: all 0.3s ease-in-out 0s;
      }
    }
  }
`

const Header = () => (
  <HeaderContainer>
    <HeaderContent>
      <Link to="/">
        <Logo />
      </Link>
      <HeaderLinks>
        <Link activeClassName="Link--is-active" to="/work">
          Work
        </Link>
        <Link activeClassName="Link--is-active" to="/services">
          Services
        </Link>
        <Link activeClassName="Link--is-active" to="/mosh-rental">
          Mosh Rental
        </Link>
        {
          //<Link activeClassName="Link--is-active" to="/blog">
          //  Blog
          //</Link>
        }
        <Link activeClassName="Link--is-active" to="/contact">
          Contact
        </Link>
      </HeaderLinks>
    </HeaderContent>
  </HeaderContainer>
)

export default Header
