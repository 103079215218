//Dimensions for device media queries and layout padding
const dimensions = {
  //pixels
  maxwidthDesktop: 1600,
  maxwidthLaptop: 1280,
  maxwidthTablet: 768,
  maxwidthMobile: 600,

  //ems
  paddingHorizontalDesktop: 4,
  paddingHorizontalTablet: 2.5,
  paddingHorizontalMobile: 2,

  marginHorizontalDesktop: 64,

  //Default values
  pagePaddingTop: 40,
  defaultSpacing: 24,
  baseSpacing: 8,
  sectionSpacing: 120,
}

export default dimensions
