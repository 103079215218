import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Logo from "components/_ui/Logo"

const FooterContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 48px;

  svg {
    max-width: 200px;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-bottom: 24px;

    svg {
      display: none;
    }
  }
`

const FooterSloganWrapper = styled("div")`
  display: flex;
  flex-direction: row;

  div:not(:last-child) {
    margin-right: 36px;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`

const FooterCreditsWrapper = styled("div")`
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`

const FooterSlogan = styled("div")`
  font-family: "NewEddy", "Inter", sans-serif;
  font-weight: normal;
  font-size: 21px;
  line-height: 96%;
  width: 96px;
`

const Footer = () => (
  <FooterContainer>
    <FooterCreditsWrapper>
      <Link to="/">
        <Logo />
      </Link>
    </FooterCreditsWrapper>
    <FooterSloganWrapper>
      <FooterSlogan>We support beauty.</FooterSlogan>
      <FooterSlogan>We consult ideas. </FooterSlogan>
      <FooterSlogan>
        We
        <br />
        like surprises.
      </FooterSlogan>
    </FooterSloganWrapper>
  </FooterContainer>
)

export default Footer
