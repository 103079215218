import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import { Global } from "@emotion/core"
import globalStyles from "styles/global"
import typeStyles from "styles/typography"
import dimensions from "styles/dimensions"
import Footer from "components/Footer"
import Header from "components/Header"
import "styles/fonts.scss"
import IntersectionObserver from "../utils/IntersectionObserver"
import "../styles/animations.scss"
import parallax from "utils/parallax"

const LayoutWrapper = styled("div")`
  overflow: hidden;
  margin-top: 120px;

  @media (max-width: 1280px) {
    margin-top: 140px;
  }

  @media (max-width: 1024px) {
    margin-top: 120px;
  }

  @media (max-width: 950px) {
    margin-top: 110px;
  }

  @media (max-width: 768px) {
    margin-top: 160px;
  }

  @media (max-width: 600px) {
    margin-top: 130px;
  }

  @media (max-width: 440px) {
    margin-top: 100px;
  }
`

const LayoutContainer = styled("div")`
  max-width: ${dimensions.maxwidthDesktop}px;
  margin: 0 auto;
  padding: 0 calc(100vw / 22.5);

  .Layout__content {
    padding-bottom: 1em;
    margin-top: 80px;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    @media (max-width: 600px) {
      margin-top: 16px;
    }
  }
`
const Layout = ({ children }) => {
  useEffect(() => {
    // Run IntersectionObserver function to collect all image tags
    IntersectionObserver()

    // init parallax
    parallax()

    // add eventListener to run parallax on scroll
    document.addEventListener("scroll", parallax)
  })
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <LayoutWrapper>
          <Header />
          <LayoutContainer className="div">
            <Global styles={[globalStyles, typeStyles]} />
            <div className="Layout">
              <main className="Layout__content">{children}</main>
              <Footer />
            </div>
          </LayoutContainer>
        </LayoutWrapper>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
